import React from 'react'
import styled from 'styled-components'
import Tiffany from '../images/portfoliopic.jpg'

const AboutContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
`

const AboutTitle = styled.h1`
  width: 100%;
  text-align: center;
  padding-top: 100px;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 80px;
`

const MeSplit = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
  flex-wrap: wrap;
  align-items: center;
`

const PortfolioPicDiv = styled.div`
  width: 80%;

  @media (min-width: 700px) {
    width: 60%;
  }

  @media (min-width: 901px) {
    width: 40%;
  }
`

const PortfolioPic = styled.img`
  width: 100%;
  border-radius: 50%;
`

const AboutBlurbDiv = styled.div`
  width: 80%;
  font-size: 18px;
  line-height: 1.5;

  @media (min-width: 901px) {
    width: 40%;
  }
`

const About = () => (
  <AboutContainer>
    <AboutTitle></AboutTitle>
    <MeSplit>
      <PortfolioPicDiv>
        <PortfolioPic src={Tiffany} alt="Tiffany Fritz"/>
      </PortfolioPicDiv>
      <AboutBlurbDiv>
        <p>
          My name is Tiffany Fritz.
        </p>
        <p>
          I am a developer, designer, and artist from Salt Lake City.
        </p>
        <p>
          I am always curious and love to learn new things.
        </p>
        <p>
          I get things done with HTML, CSS, JavaScript, React, Photoshop,
          and Illustrator.
        </p>
        <p>
          My first love was art. I draw inspiration from the natural world,
          dreams, and the subconscious. These sources of inspiration represent
          different realms that are both seperate, yet intertwining places
          that influence our everyday being. The environments in which we
          all create and take up space have always fascinated me - the internet
          being another one of those places. Through the path of digital art,
          I was led toward web development. My interest in the internet space
          made me not only want to consume it, but to be able to create it as
          well - not just the pleasing visuals, but also the functionality and
          interactivity.
        </p>
      </AboutBlurbDiv>
    </MeSplit>
  </AboutContainer>
)

export default About
