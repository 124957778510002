import styled from 'styled-components'

export const ProjectLink = styled.a`
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  color: #858585;
`

export const ProjectTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0px 25px;
`

export const ProjectContainer = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: 18px;
  line-height: 1.5;
`

export const ProjectTitle = styled.h3`
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  font-size: 23px;
`

export const ProjectImage = styled.img`
  width: 100%;
  height: auto;
`

export const Copyright = styled.div`
  margin-top: 60px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
`
