import React from 'react'
import {
  ProjectTypeContainer,
  ProjectContainer,
  ProjectImage,
  ProjectTitle
} from './styles'
import TMwedding from '../../../images/tmweddingInvites.png'
import Programs from '../../../images/programs.png'
import SBbabyshower from '../../../images/SBbabyshowerinvitations.png'

const Design = () => (
  <ProjectTypeContainer>
    <ProjectContainer>
      <ProjectTitle>Wedding Invitations</ProjectTitle>
      <ProjectImage src={TMwedding} alt="Tiffany and Michael wedding invitation"/>
      <p>
        Designed and produced custom wedding invitations using a variety of digital and physical mediums.
      </p>
    </ProjectContainer>
    <ProjectContainer>
      <ProjectTitle>Wedding Programs</ProjectTitle>
      <ProjectImage src={Programs} alt="Tiffany and Michael wedding programs"/>
      <p>
        Designed and produced custom wedding programs using a variety of digital and physical mediums.
      </p>
    </ProjectContainer>
    <ProjectContainer>
      <ProjectTitle>Baby Shower Invites</ProjectTitle>
      <ProjectImage src={SBbabyshower} alt="baby shower invitation"/>
      <p>
        Designed and produced baby shower invitations with a "April Showers" theme.
      </p>
    </ProjectContainer>
  </ProjectTypeContainer>
)

export default Design
