import React from "react"
import styled from 'styled-components'

import MandalaBackground from '../images/mandalabackground.png'
import Logo from '../components/logo'
import About from '../components/about'
import Contact from '../components/contact'
import Projects from '../components/projects/projects'

const IndexBody = styled.div`
  width: 100%;
  background-image: url(${MandalaBackground});
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: fixed;
  z-index: -100;
`

const Body = styled.body`
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
  color: #858585;
  margin: 0;
`

const IndexPage = () => (
  <Body>
    <IndexBody>
    </IndexBody>
    <div>
      <Logo />
      <About />
      <Contact />
      <Projects />
    </div>
  </Body>
)

export default IndexPage
