import React from 'react'
import styled from 'styled-components'
import LogoImage from '../images/inthegullylogo4.svg'

const LogoHouse = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 500px;
  overflow: hidden;

  @media (width >= 700px) and (width <= 900px) {
    height: 800px;
  }

  @media (min-width: 901px) {
    height: 1000px;
  }
`
const TransparentLogo = styled.div`
  background-image: url(${LogoImage});
  background-position: center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;

  @media (width >= 700px) and (width <= 900px) {
    height: 800px;
  }

  @media (min-width: 901px) {
    height: 1000px;
  }
`

const Logo = () => (
  <LogoHouse>
    <TransparentLogo>
    </TransparentLogo>
  </LogoHouse>
)

export default Logo
