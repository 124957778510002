import React from 'react'
import styled from 'styled-components'
import Development from './components/development'
import Design from './components/design'
import Art from './components/art'

const ProjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  justify-content: center;
`

const ProjectsTitle = styled.h1`
  width: 100%;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  text-align: center;
  padding-top: 100px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 30px;
`

const SubprojectTitle = styled.h2`
  width: 100%;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 35px;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    margin-bottom: 85px;
  }
`

const Projects = () => (
  <ProjectsContainer>
    <ProjectsTitle>
    </ProjectsTitle>
    <SubprojectTitle>
      Development
    </SubprojectTitle>
    <Development />
    <SubprojectTitle>
      Design
    </SubprojectTitle>
    <Design />
    <SubprojectTitle>
      Art
    </SubprojectTitle>
    <Art />
  </ProjectsContainer>
)

export default Projects
