import React from 'react'
import styled from 'styled-components'
import GitHub from '../images/githublogo.png'
import LinkedIn from '../images/linkedinlogo.png'
import Insta from '../images/instagramlogo.png'
import Twitter from '../images/Twitterlogo.png'

const ContactContainer = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 80px;
`

const Email = styled.h2`
  width: 100%;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  margin-bottom: 80px;
`

const ContactLogosFlex = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 50px;
`

const ContactLogo = styled.img`
  width: 50%;
`

const ContactLink = styled.a`
  text-align: center;
  display: block;
`

const Contact = () => (
  <ContactContainer>
    <Email>fritzty@gmail.com</Email>
    <ContactLogosFlex>
      <div>
        <ContactLink href="https://github.com/inthegully" target="_blank" rel="noopener noreferrer">
          <ContactLogo src={GitHub} alt="Github logo"/>
        </ContactLink>
      </div>
      <div>
        <ContactLink href="https://www.linkedin.com/in/inthegully/" target="_blank" rel="noopener noreferrer">
          <ContactLogo src={LinkedIn} alt="Linkedin logo"/>
        </ContactLink>
      </div>
      <div>
        <ContactLink href="https://www.instagram.com/inthegully/" target="_blank" rel="noopener noreferrer">
          <ContactLogo src={Insta} alt="Instagram logo"/>
        </ContactLink>
      </div>
      <div>
        <ContactLink href="https://twitter.com/inthegully" target="_blank" rel="noopener noreferrer">
          <ContactLogo src={Twitter} alt="Twitter logo"/>
        </ContactLink>
      </div>
    </ContactLogosFlex>
  </ContactContainer>
)

export default Contact
