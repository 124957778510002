import React from 'react'
import {
  ProjectTypeContainer,
  ProjectContainer,
  ProjectImage,
  ProjectLink,
  ProjectTitle
} from './styles'
import SandG from '../../../images/sandg.png'
import DDell from '../../../images/DDell.png'
import Purple from '../../../images/Purple.png'
import Nav from '../../../images/Nav.png'
import JillandAri from '../../../images/JillandAri.png'


const Development = () => (
  <ProjectTypeContainer>
      <ProjectLink href="http://app.nav.com" target="_blank" rel="noopener noreferrer">
        <ProjectContainer>
          <ProjectTitle>Nav</ProjectTitle>
          <ProjectImage src={Nav} alt="Nav Application"/>
          <p>
            At Nav I build and maintain the application that connects small business owners to smart financing options. I use React, Redux, and Styled Components to build flexible marketplaces that are used across multiple parts of the application as well as on partner sites.
          </p>
        </ProjectContainer>
      </ProjectLink>
      <ProjectLink href="http://www.purple.com/" target="_blank" rel="noopener noreferrer">
        <ProjectContainer>
          <ProjectTitle>Purple</ProjectTitle>
          <ProjectImage src={Purple} alt="Purple Mattress Website"/>
          <p>
            Purple is a billion dollar mattress company. I built and maintained the e-commerce site, including multiple new product launches, wrote Ruby scripts for the world's largest Shopify site, implemented tracking and analytics, as well as cross-browser optimization. Using Optimizely, I ran A/B tests and increased conversion rates based on my findings. Worked on building out the new version of the site using the Craft CMS with Sass, ES6+.
          </p>
        </ProjectContainer>
      </ProjectLink>
      <ProjectLink href="http://www.jillandari.com" target="_blank" rel="noopener noreferrer">
        <ProjectContainer>
          <ProjectTitle>Jill and Ari Wedding</ProjectTitle>
          <ProjectImage src={JillandAri} alt="Jill and Ari Wedding Website"/>
          <p className="project-description">
            Designed and built the wedding website using React and latest JS tools.
          </p>
        </ProjectContainer>
      </ProjectLink>
      <ProjectLink href="http://www.stevenandgillian.com/" target="_blank" rel="noopener noreferrer">
        <ProjectContainer>
          <ProjectTitle>Finklestein Wedding</ProjectTitle>
          <ProjectImage src={SandG} alt="Steven and Gillian Wedding Website"/>
          <p>
            Designed and created all art for the wedding website of Steve and Gillian
            using React and latest JS tools.
          </p>
        </ProjectContainer>
      </ProjectLink>
      <ProjectLink href="http://www.dimpledellwild.com/" target="_blank" rel="noopener noreferrer">
        <ProjectContainer>
          <ProjectTitle>Keep Dimple Dell Wild</ProjectTitle>
          <ProjectImage src={DDell} alt="Keep dimple dell wild"/>
          <p>
            Designed and built nonprofit website for the protection of Dimple Dell Nature Reserve in Sandy, UT.
          </p>
        </ProjectContainer>
      </ProjectLink>
    </ProjectTypeContainer>
)

export default Development
