import React from 'react'
import {
  ProjectTypeContainer,
  ProjectContainer,
  ProjectImage,
  ProjectTitle,
  Copyright
} from './styles'
import Mandalaimg from '../../../images/mandala.png'
import Cactus from '../../../images/CactusHead.png'
import Salvation from '../../../images/[Salve]ation.png'

const Art = () => (
  <ProjectTypeContainer>
    <ProjectContainer>
      <ProjectTitle>Mandala</ProjectTitle>
      <ProjectImage src={Mandalaimg} alt="Mandala"/>
      <p>
        Mandala originally drawn with ink and then recreated digitally in Illustrator.
      </p>
    </ProjectContainer>
    <ProjectContainer>
      <ProjectTitle>Cactus Head</ProjectTitle>
      <ProjectImage src={Cactus} alt="salvation"/>
      <p>
        Reimagining of Michael in the desert. Posca paint pens on cardstock.
      </p>
    </ProjectContainer>
    <ProjectContainer>
      <ProjectTitle>[Salve]ation</ProjectTitle>
      <ProjectImage src={Salvation} alt="salvation"/>
      <p>
        Product art and design for [Salve]ation hand salves.
      </p>
    </ProjectContainer>
    <Copyright>
      &copy; inthegully
    </Copyright>
  </ProjectTypeContainer>
)

export default Art
